<template>
  <v-container>
    <v-row class="about-body primary--text text-center">
      <v-col cols="12">
        <h2 style="margin-bottom: 30px;">{{title}}</h2>
        <!-- <iframe :src="pdfLink" frameborder="0" scrolling="auto" height="800px" width="100%"></iframe> -->
        <v-img :src="srcOne" aspect-ratio="1.7" contain></v-img>
        <v-img v-if="srcTwo" :src="srcTwo" aspect-ratio="1.7" contain class="mt-8"></v-img>
        <br>
        <h5 style="margin-bottom: 30px;"><a href="javascript:history.back()">Back</a></h5>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
let filePathOne = '';
let filePathTwo = '';
let fileTitle = '';

export default {
  name: 'viewDoc',
  components: {},
  beforeRouteEnter(to, from, next) {
    const docName = to.params.docName;
    if (!docName) {
      console.log('nav out');
      next();
    } else {
      if (docName === 'motor-vehicle-purchase-agreement') {
        fileTitle = 'Motor Vehicle Purchase Agreement';
        filePathOne = require('../../assets/doc/bill_of_sale.jpg');
      } else if (docName === 'application-for-title') {
        fileTitle = 'Application for Texas Title';
        filePathOne = require('../../assets/doc/texas_application_for_title.jpg');
      } else if (docName === 'certificate-of-origin') {
        fileTitle = 'Certificate of Origin';
        filePathOne = require('../../assets/doc/texas_title_front.jpg');
        filePathTwo = require('../../assets/doc/texas_title_back.jpg');
      } else if (docName === 'vehicle-inspection-report') {
        fileTitle = 'Vehicle Inspection Report';
        filePathOne = require('../../assets/doc/texas_vehicle_inspection.jpg');
      } else if (docName === 'trade-in-annex') {
        fileTitle = 'Trade in Annex';
        filePathOne = require('../../assets/doc/trade_in_annex.jpg');
      } else if (docName === 'texas-odometer-disclosure') {
        fileTitle = 'Texas Odometer Disclosure';
        filePathOne = require('../../assets/doc/texas_odometer_disclosure.jpg');
      } else if (docName === 'limited-power-of-attorney') {
        fileTitle = 'Limited Power of Attorney';
        filePathOne = require('../../assets/doc/texas_limited_power_of_attorney.jpg');
      }
      
      
      else {
        fileTitle = 'Sorry, that document was not found';
      }
      next();
    }
  },
  computed: {
    title: () => {
      return fileTitle;
    },
    
  },
  data: function() {
    return {
      docs: {
        path: 'motorVehiclePurchaseAgreement',
        title: 'asdf',
      },
      srcOne: filePathOne,
    srcTwo: filePathTwo,
    };
  },
};
</script>

<style scoped>
.about-body {
  margin: 0;
}
</style>
